import React, { useState, useEffect } from 'react';
import '../css/tw.css'
import '@fontsource/poppins';
const Typewriter = ({ text, speed, repeatAfter }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const typeText = () => {
      if (currentIndex < text.length) {
        setDisplayText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(intervalId);
        setTimeout(() => {
          setCurrentIndex(0);
          setDisplayText('');
        }, repeatAfter);
      }
    };

    const intervalId = setInterval(typeText, speed);

    return () => clearInterval(intervalId);
  }, [currentIndex, text, speed, repeatAfter]);

  return (
    <span className='twtext'>
      {displayText}
      <span className="tcursor">|</span>
    </span>
  );
};

function Tw() {
  const textToType = 'Welcome To Our Era !';
  const typingSpeed = 100; // in milliseconds
  const repeatAfter = 5000; // in milliseconds

  return (
    <div className='twc' >
      <h1>
        <Typewriter text={textToType} speed={typingSpeed} repeatAfter={repeatAfter} />
      </h1>
    </div>
  );
};

export default Tw;
