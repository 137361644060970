import React,{useState} from "react";
import '../css/HamburgerMenu.css';
import { Link } from 'react-router-dom';
import CustomModal from './CustomModal';

const HamburgerMenu = ({scrollToSection}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
      setIsModalOpen(true);
  };

  const closeModal = () => {
      setIsModalOpen(false);
  };
  const handleScroll = (sectionId) => {
    // Call scrollToSection with the desired section ID
    scrollToSection(sectionId);
  };
    const[i1,newI1] = useState("i1")
    const[i2,newI2] = useState("i1")
    const[i3,newI3] = useState("i1")
    const[Menu,newMenu] = useState("hamnav")
    const[isClicked,setClicked] = useState(false)

    const changeham = () => {
      if(!isClicked) {
        newI1("ni1")
        newI2("ni2")
        newI3("ni3")
        newMenu("hamnav1")
      }
      else{
        newI1("i1")
        newI2("i1")
        newI3("i1")
        newMenu("hamnav")
      }
      setClicked(!isClicked)
    }

    return (
        <>
            <div class="hamicon" onClick={changeham}>
                <div className={i1} ></div>
                <div className={i2} ></div>
                <div className={i3} ></div>
            </div>
      <div className={Menu} class="hamnav" onClick={changeham}>
        <div class="hamnavbar">
          <Link className="linkb" to="/"><button class="hButton" onClick={() => handleScroll('home')}>Home</button></Link>
          <Link className="linkb" > <button class="hButton" onClick={() => handleScroll('sqx')} >Ecosystem</button></Link>
          <Link className="linkb"><button class="hButton" onClick={() => handleScroll('about')} >About Us</button></Link>
          <Link className="linkb"><button class="hButton" onClick={() => handleScroll('team')} >Who is Founder?</button></Link>
          <button onClick={openModal} className="sButton">Subscribe</button>
          <CustomModal isOpen={isModalOpen} onRequestClose={closeModal} />

        </div>
        </div>
      </>
    );
  }
  
  export default HamburgerMenu;