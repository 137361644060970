
import React from 'react';
import '../css/rect1.css';
import c1 from '../media/c1.png'
import c2 from '../media/c2.png'

import '@fontsource/poppins';

function Rect1({head,para}) {

  return (
    <>
<div className='rbody1'>
<img src={c1} className='r1-hc1' alt='qubicsquare'/>
        <img src={c2} className='r1-hc2' alt='qubicsquare'/>
    <div className='rect1'>
      <div className='rect1-head'>{head}</div>
      <div className='rect1-para'>{para}</div>
    </div>
</div>
   </>



  );
}

export default Rect1;
