// Modal.js
import React from 'react';
import Modal from 'react-modal';
import Email from './email'
import '../css/cm.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const CustomModal = ({ isOpen, onRequestClose }) => {
    
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Example Modal" className="modal"
        >
            <button className='closeb' onClick={onRequestClose}><FontAwesomeIcon icon={faTimes} /></button>
            <br/>
            <div><Email /></div>
            
        </Modal>
    );
};

export default CustomModal;
