import React from 'react';
import "@fontsource/michroma";
import xlogo from '../media/xlogo.png'
import lnlogo from '../media/lnlogo.png'
import Ilogo from '../media/I.png'
import '../css/profile.css'

function Profile({ImageSrc,xlink,lnlink,Name,Role,Ilink,showXLogo,showLnlogo,showIlogo}) {
 

  return (
    <>
 <div className='pcont'>
      <div className='pbox'>
      <img src={ImageSrc} className="pimage" width={5000} height={5000} alt="Squarex Collective"/>
      </div>
      <div className='pdis'>
      <div className='pname'>{Name}</div>
      <div className='prole'>{Role}</div>
      </div>

      <div className='scont'>
       {showXLogo && <a href={xlink} target='_blank'rel="noreferrer" ><img src={xlogo} className="pxlogo" width={500} height={500} alt="xlogo"/></a>}
     {showLnlogo && <a href={lnlink} target='_blank'rel="noreferrer"><img src={lnlogo} className="plnlogo" width={500} height={500} alt="xlogo"/></a>}
     {showIlogo && <a href={Ilink} target='_blank'  rel="noreferrer"><img src={Ilogo} className="pIlogo" width={500} height={500} alt="xlogo"/></a>}
      </div>
      
    </div>
    </>
  );
}


export default Profile;
