
import React from 'react';
import '../css/socialmedia.css'
import Twitter from '../media/xlogo.png' 
import Insta from '../media/I.png' 
import Linke from '../media/linkedin.png' 


import "@fontsource/michroma";

function SocialMedia() {

  return (
    <div className="sm">
        <a className='smbox' href='https://twitter.com/QubicsquareTech' target='_blank' rel="noreferrer">
        <div >
            <img className='xlogo' src={Twitter} alt='qs'/>
        </div>
        </a>
        <a className='smbox' href='https://www.linkedin.com/company/qubicsquare/' target='_blank' rel="noreferrer">
        <div >
            <img src={Linke}  alt='qs'/>
        </div>
        </a>
        <a className='smbox' href='https://www.instagram.com/qubicsquaretech/' target='_blank' rel="noreferrer">
        <div >
            <img className='ilogo' src={Insta}  alt='qs'/>
        </div>
        </a>

  </div>
  );
}

export default SocialMedia;
