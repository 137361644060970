
import React from 'react';
import './css/sqx.css'
import '@fontsource/poppins';
import banner from './media/qslogo.png'
import Product1 from './prop/product1';


function Sqx() {

  return (
    <>
    <div className='sqx'>
        <h1 className='sqxhead'>ECOSYSTEM</h1>
        <div className='sqxbody'>
            <Product1 plink={'https://squarexlabs.io'} pimage={banner} parah={'Innovation Hub'} para={'Where we Reserch, Develop and launch '} quote={'Next Chapter'}/>
            <Product1 plink={'https://squarexlabs.io'} pimage={banner} parah={'NFT Pass'} para={'Membership pass with unlimited sake'} quote={'Coming Soon'}/>
            <Product1 plink={'https://squarexlabs.io'} pimage={banner} parah={'Cryptocurrency'} para={"Qubicsquare's ecosystem run our own token"} quote={'Coming Soon'}/>
            <Product1 plink={'https://squarexlabs.io'} pimage={banner} parah={'Web3 + Ai'} para={'Develop web3 + Ai based software'} quote={'Coming Soon'}/>
        </div>
    </div>
   </>



  );
}

export default Sqx;
