import React from "react";
import { Link } from 'react-router-dom';
import HamburgerMenu from "./HamburgerMenu";
import '../css/navigation.css';
import logo from '../media/qslogotxt.png'
import logos from '../media/qslogo.png'
import CustomModal from './CustomModal';
import { useState } from 'react';


function NavigationBar({ scrollToSection }) {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
      <div className="nav">
        <Link to="/"><div class="imgc" >
        <img class="logo" src={logo} alt="SAAY TECH"/>
        <img class="logos" src={logos} alt="SAAY TECH"/>
          </div></Link>
          <div class="ham">
            <HamburgerMenu scrollToSection={scrollToSection}/>
            
        </div>
        <div className="connectb">
                <button onClick={openModal} className="sub-button">Subscribe</button>
                <CustomModal isOpen={isModalOpen} onRequestClose={closeModal} />
        </div>
      </div>
    );
  }
  
  export default NavigationBar;
  