import React from 'react';
import "@fontsource/michroma";
import '../css/product1.css'

function Product1({plink,para,pimage,quote,parah}) {
 const arr='>';

  return (
    <>
 <div className='product1'>
    <div className='imagepc1'>
        <img src={pimage} className='simage1' alt='qs'/>
    </div>
    <div className='ppara1'>
      <span className='parah1'>{parah}</span>
      <p className='parab1'>{para}</p>
      <br/>
      <a href={plink}  target='_blank' rel='noreferrer'>
        <button className='sqxbutton'>
        {arr}
        </button>
      </a>
      <br/>
      {quote}
    </div>
      
    </div>
    </>
  );
}


export default Product1;
