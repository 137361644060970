import React, { useState } from 'react';
import './css/email.css';
import './css/sub.css';
import axios from 'axios';
import '@fontsource/poppins';
import { ColorRing } from 'react-loader-spinner';

function Subscibe() {
  
  const [input, setInput] = useState('');
  const [Message,setMessage] = useState(false);
  const [isLoading,setLoading]=useState(false);

  const handleEmailChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    
    if (input) {
      console.log(input);
      try {
        await axios.get('https://script.google.com/macros/s/AKfycbxsF7Oc7B7gtbVdZOdoCeZWDGn-MYzqxThvjYzkKaCzXxnhUsGzCj_fqWq3axNEnFEpFQ/exec', {
          params: {
            email: input
          }
        });
    
        //console.log('Email added successfully:', response.data);
      } catch (error) {
        console.error('Error adding email to sheet:', error.message);
        setLoading(false)
      }

      setInput('');
      setLoading(false);
      setMessage(true);
      setTimeout(() => {
        setMessage(false);
      },3000)
    }
  };

  return (
    <div className="sub-email" >
        <div className='sub-emailc'>
        <div className='sub-get'><div className='pget'>Subscribe for Newsletter</div></div><br/>
      <form onSubmit={handleSubmit} class='sub-forme'>
        <input
          className="emailin"
          type="email"
          placeholder="Email Address"
          value={input}
          onChange={handleEmailChange}
          required
        /><br/>
        <button class='sbutton' type="submit">
          {
            isLoading?<ColorRing
            visible={true}
            height="50"
            width="50"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-wrapper"
            colors={['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']}
            />:<div>Suscribe</div>
          }
        </button>

      </form>
        <div className='sub-thanks' >
          {Message? <div>&#10004; <br/>Thanks for Subscribing </div>:""}
        </div>
        <br/>
        </div>
    </div>
  );
}

export default Subscibe;
