
import React from 'react';
import '../css/rect2.css'
import c1 from '../media/c1.png'
import c2 from '../media/c2.png'

import '@fontsource/poppins';

function Rect2({head,para}) {

  return (
    <>
<div className='rbody2'>
<img src={c1} className='r2-hc1' alt='qubicsquare' />
        <img src={c2} className='r2-hc2'alt='qubicsquare'/>
    <div className='rect2'>
      <div className='rect2-head'>{head}</div>
      <div className='rect2-para'>{para}</div>
    </div>
</div>
   </>



  );
}

export default Rect2;
