import React from 'react';
import logo from './media/qslogo.png' 
import c1 from './media/c1.png'
import c2 from './media/c2.png'
import '@fontsource/poppins';
import Team from './Team'
import "./css/home.css"
import Footer from './prop/Footer';
import NavigationBar from './prop/NavigationBar';
import Line from './prop/Line';
import About from './about';
import Tw from './prop/typewriter';
import Sqx from './sqx';
import Subscibe from './sub';

function Home() {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const arr='>';

  return (
    <>
    <div className="home" >
      <div className='homec' id='home'>
        <img src={c1} className='hc1' alt='qubicsquare' />
        <img src={c2} className='hc2' alt='qubicsquare'/>
        <img src={c1} className='hc3' alt='qubicsquare'/>
        <img src={c2} className='hc4' alt='qubicsquare'/>
      <div class="imagec">
        <img src={logo} className='image' alt='qubicsquare'/>
      </div>
      <div class="upper">
      <a href="https://squarexlabs.io" target='_blank' rel="noreferrer">
            <button className='sqxb'>Announcing SquareX Labs <span>{arr}</span></button>
          </a>
        <Tw/>
        <h1 className='para1'>Empowering Your New Era Experience</h1>
          <h2 class="para">
          Pioneers India's forefront in Web3, Cryptocurrency, and Artificial Intelligence, leading the way in transformative tech solutions for a cutting-edge future
          </h2>

      </div>
      </div>
      <section id='sqx'><Sqx/></section>
      <Subscibe/>
      <section id='about'><About /></section>
      <section id='team'><Team /></section>
      <Subscibe/>
  </div>
  <Line/>
      <Footer/>
      <NavigationBar scrollToSection={scrollToSection} />
  
  </>
  );
}

export default Home;
